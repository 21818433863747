import { AppProps } from 'next/app'
import { store } from '#/src/state/store'
import { customTheme } from '#/src/styles/customTheme'
import { ChakraProvider } from '@chakra-ui/react'
import { Provider } from 'shared-redux'

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <Provider store={store}>
      <ChakraProvider resetCSS theme={customTheme}>
        <Component {...pageProps} />
      </ChakraProvider>
    </Provider>
  )
}
